import $api from "@/http";
import store from "@/store";

async function getAgents(page, size, isActive, organisationId, filialId) {
  return await $api.get('/agent/listByOrganisationId', {
    params: {
      organisationId: organisationId,
      filialId: filialId,
      isActive: isActive,
      pageNo: page,
      pageSize: size,
    }
  })
}

function createAgent(datas){
  return $api.post('/agent/createAgent', {
    birthDate: datas.birthDate,
    email: datas.email,
    firstName: datas.firstName,
    gender: datas.gender,
    isActive: datas.isActive,
    lastName: datas.lastName,
    filialId: datas.filialId,
    password: datas.password,
    patronymic: datas.patronymic,
    phone: datas.phone,
    username: datas.username,
  },
      {
        params: {
          whoIsIt: datas.whoIsIt
        }
      })
}

function editAgent(datas){
  return $api.put('/agent/updateAgent', {
    id: datas.id,
    birthDate: datas.birthDate,
    email: datas.email,
    firstName: datas.firstName,
    gender: datas.gender,
    isActive: datas.isActive,
    lastName: datas.lastName,
    filialId: datas.filialId,
    password: datas.password,
    patronymic: datas.patronymic,
    phone: datas.phone,
    username: datas.username,
  },
      {
        params: {
          whoIsIt: datas.whoIsIt
        }
      })
}

async function blackListAgent(datas) {
  return await $api.post('/agent/addToBlackList', {}, {
    params: {
      blackListDate : datas.blackListDate,
      blackListReason : datas.blackListReason,
      userId : datas.userId,
    },
  })
}

async function unblockAgent(id) {
  return await $api.post('/agent/removeFromBlackList', {}, {
    params: {
      userId: id
    },
  })
}

export default {
  getAgents,
  createAgent,
  editAgent,
  blackListAgent,
  unblockAgent
}
