
const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'side-menu-dashboard',
        title: 'Imushestvo',
        subMenu: [
          {
            pageName: 'AddApplication',
            title: 'AddApplication',
            permission: ['SUPER_ADMIN'],
          },
          {
            pageName: 'ApplicationList',
            title: 'ApplicationList',
            permission: ['SUPER_ADMIN'],
          }
        ]
      },{
        icon: 'DatabaseIcon',
        pageName: 'side-menu-dashboard',
        title: 'Dictionaries',
        subMenu: [
          {
            pageName: 'Oblasts',
            title: 'Areas',
            permission: ['DICT_READ'],
          },
          {
            pageName: 'TypeFilial',
            title: 'TypeFilial',
            permission: ['DICT_READ'],
          },
          {
            pageName: 'Usluga',
            title: 'Usluga',
            permission: ['DICT_READ'],
          },
        ]
      },
      {
        icon: 'LockIcon',
        pageName: 'side-menu-dashboard',
        title: 'Administration',
        subMenu: [
          {
            pageName: 'UserRole',
            title: 'UserRoles',
            permission: ['SUPER_ADMIN'],
          },
          {
            pageName: 'AccessControl',
            title: 'AccessControl',
            permission: ['SUPER_ADMIN'],
          },
          {
            pageName: 'Users',
            title: 'Users',
            permission: ['SUPER_ADMIN'],
          }
          // {
          //   pageName: 'Logs',
          //   title: 'logs',
          //   permission: ['SUPER_ADMIN'],
          // },
        ]
      },
      {
        icon: 'MenuIcon',
        pageName: 'OrganisationList',
        title: 'Organisations',
        permission: ['ORG_READ'],
      },
      {
        icon: 'UsersIcon',
        pageName: 'Agents',
        title: 'Agents',
        permission: ['AGENT_READ'],
      },
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
