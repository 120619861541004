const state = {
    itemName: localStorage.getItem('language') || 'RU'
}

const mutations = {
    setItemName(state, name) {
        state.itemName = name
        localStorage.setItem('language', name)
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
