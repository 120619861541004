import $api from '@/http'

/** COUNTRIES ENDPOINTS START **/
async function getUslugi() {
    return await $api.get('/usluga/list')
}

async function getUslugiWithPagination({ page, size }) {
    return await $api.get('/usluga/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function createUsluga({ code, nameRu, nameKg, price }) {
    return await $api.post('/usluga/create', {
        code,
        nameRu,
        nameKg,
        price
    })
}

async function editUsluga({ id, code, nameRu, nameKg, price  }) {
    return await $api.put('/usluga/update', {
        id,
        code,
        nameRu,
        nameKg,
        price
    })
}

async function deleteUsluga({ id }) {
    return await $api.delete('/usluga/delete', {
        params: {
            id,
        }
    })
}

/** COUNTRIES ENDPOINTS END **/

export default {
    getUslugi,
    getUslugiWithPagination,
    createUsluga,
    editUsluga,
    deleteUsluga
}
