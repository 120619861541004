import $api from "@/http";
import store from "@/store";

/** USER ROLE START **/

async function getRoles() {
  return await $api.get('/userRole/list')
}
async function getRolesWithPage({pageNo, pageSize}) {
  return await $api.get('/userRole', {
    params: {
      pageNo: pageNo,
      pageSize: pageSize
    }
  })
}

async function getAllPermissions() {
  return await $api.get('/userRole/allPermissions')
}


async function getRoleById(roleId) {
  return await $api.get('/userRole/getById', {
    params: {
      id: roleId
    }
  })
}


function editRolePermissions(roleId, checkedPermissions){
  return $api.put('/userRole/updateRolePermissions', checkedPermissions, {
    params: {
      id: roleId
    }
  })
}

function createRole({name, nameRu}){
  return $api.post('/userRole/create', {
    name: name,
    nameRu: nameRu
  })
}

function editRole({ id, name, nameRu}){
  return $api.post('/userRole/update', {
    id: id,
    name: name,
    nameRu: nameRu
  })
}

/** USER ROLE END **/


/** USERS START **/
async function getUsers(page, size) {
  return await $api.get('/user/list', {
    params: {
      pageNo: page,
      pageSize: size
    }
  })
}

async function getUserById(id) {
  return await $api.get('/user/getById', {
    params: {
      id: id
    }
  })
}

function createUser(datas, roles){
  return $api.post('/user/create', {
    birthDate: datas.birthDate,
    blackListDate: datas.blackListDate,
    blackListReason: datas.blackListReason,
    email: datas.email,
    firstName: datas.firstName,
    gender: datas.gender,
    isActive: datas.isActive,
    lastName: datas.lastName,
    filialId: datas.filialId,
    password: datas.password,
    patronymic: datas.patronymic,
    phone: datas.phone,
    username: datas.username,
    userRoles: [{ id: datas.userRoles} ]
  })
}

function editUser(datas, roles){
  return $api.put('/user/update', {
    id: datas.id,
    birthDate: datas.birthDate,
    blackListDate: datas.blackListDate,
    blackListReason: datas.blackListReason,
    email: datas.email,
    firstName: datas.firstName,
    gender: datas.gender,
    isActive: datas.isActive,
    lastName: datas.lastName,
    filialId: datas.filialId,
    password: datas.password,
    patronymic: datas.patronymic,
    phone: datas.phone,
    username: datas.username,
    userRoles: [{ id: datas.userRoles} ]
  })
}

function editUserManually(datas, roles){
  return $api.put('/user/updateWhenRegister', {
    id: datas.id,
    birthDate: datas.birthDate,
    email: datas.email,
    firstName: datas.firstName,
    gender: datas.gender,
    lastName: datas.lastName,
    patronymic: datas.patronymic,
    phone: datas.phone,
    username: datas.username,
  })
}

function hasAccess(permissions) {
  const userPermissions = store.state.auth.user != null ? store.state.auth.user.permissions : null
  let isAccess = false
  userPermissions.forEach(item => {
    permissions.forEach(item2 => {
      if (item.name === item2 || item.name === 'SUPER_ADMIN') {
        isAccess = true
      }
    })
  })
  return isAccess
}

function hasRole(role) {
  const userPermissions = store.state.auth.user != null ? store.state.auth.user.user.userRoles : null
  let isAccess = false
  if(userPermissions) {
    isAccess = userPermissions.includes(role)
  }
  return isAccess
}
/** USERS END **/


async function getLogs({pageNo, pageSize,username, startDate, endDate}) {
  return await $api.get('/log/list', {
    params: {
      pageNo: pageNo,
      pageSize: pageSize,
      username,
      startDate,
      endDate
    }
  })
}
export default {
  getRoles,
  getRolesWithPage,
  getAllPermissions,
  getRoleById,
  editRolePermissions,
  hasAccess,
  hasRole,
  createRole,
  editRole,
  getUsers,
  getUserById,
  createUser,
  editUser,
  editUserManually,
  getLogs
}
