import $api from '@/http'

/** COUNTRIES ENDPOINTS START **/
async function getCountries() {
    return await $api.get('/country/list')
}

async function getCountriesWithPagination({ page, size }) {
    return await $api.get('/country/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getCountryById({ id }) {
    return await $api.get('/country/getById/' + id)
}

async function createCountry({ code, nameRu, nameKg }) {
    return await $api.post('/country/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editCountry({ id, code, nameRu, nameKg }) {
    return await $api.put('/country/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteCountry({ id }) {
    return await $api.delete('/country/delete', {
        params: {
            id,
        }
    })
}

/** COUNTRIES ENDPOINTS END **/


/** OBLAST ENDPOINTS START **/
async function getOblasts() {
    return await $api.get('/oblast/list')
}

async function getOblastsWithPagination({ page, size }) {
    return await $api.get('/oblast/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getOblastById({ id }) {
    return await $api.get('/oblast/getById/' + id)
}

async function createOblast({ code, nameRu, nameKg }) {
    return await $api.post('/oblast/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editOblast({ id, code, nameRu, nameKg }) {
    return await $api.put('/oblast/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteOblast({ id }) {
    return await $api.delete('/oblast/delete', {
        params: {
            id,
        }
    })
}

/** OBLASTS ENDPOINTS END **/


/** RAYONS ENDPOINTS START **/
async function getRayons() {
    return await $api.get('/rayon/list')
}

async function getRayonsWithPagination({ page, size }) {
    return await $api.get('/rayon/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getRayonById({ id }) {
    return await $api.get('/rayon/getById/' + id)
}

async function getRayonByOblastId({ id }) {
    return await $api.get('/rayon/getByOblastId', {
        params: {
            pageNo: 1,
            pageSize: 10000,
            id: id,
        }
    })
}

async function createRayon({ code, nameRu, nameKg, oblastId }) {
    return await $api.post('/rayon/create', {
        code,
        nameRu,
        nameKg,
        oblastId,
    })
}

async function editRayon({ id, code, nameRu, nameKg, oblastId }) {
    return await $api.put('/rayon/update', {
        id,
        code,
        nameRu,
        nameKg,
        oblastId,
    })
}

async function deleteRayon({ id }) {
    return await $api.delete('/rayon/delete', {
        params: {
            id,
        }
    })
}

/** RAYONS ENDPOINTS END **/


/** TYPE-TRANSPORT ENDPOINTS START **/
async function getTransportTypes() {
    return await $api.get('/typeTransport/list')
}

async function getTransportTypesWithPagination({ page, size }) {
    return await $api.get('/typeTransport/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getTransportTypeById({ id }) {
    return await $api.get('/typeTransport/getById/' + id)
}

async function createTransportType({ code, nameRu, nameKg, stavka }) {
    return await $api.post('/typeTransport/create', {
        code,
        nameRu,
        nameKg,
        stavka
    })
}

async function editTransportType({ id, code, nameRu, nameKg, stavka }) {
    return await $api.put('/typeTransport/update', {
        id,
        code,
        nameRu,
        nameKg,
        stavka
    })
}

async function deleteTransportType({ id }) {
    return await $api.delete('/typeTransport/delete', {
        params: {
            id,
        }
    })
}

/** TYPE-TRANSPORT ENDPOINTS END **/


/** VID-DOKUMENT ENDPOINTS START **/
async function getDocumentTypes() {
    return await $api.get('/vidDocument/list')
}

async function getDocumentTypesWithPagination({ page, size }) {
    return await $api.get('/vidDocument/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getDocumentTypeById({ id }) {
    return await $api.get('/vidDocument/getById/' + id)
}

async function createDocumentType({ code, nameRu, nameKg }) {
    return await $api.post('/vidDocument/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editDocumentType({ id, code, nameRu, nameKg }) {
    return await $api.put('/vidDocument/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteDocumentType({ id }) {
    return await $api.delete('/vidDocument/delete', {
        params: {
            id,
        }
    })
}

/** VID-DOKUMENT ENDPOINTS END **/


/** TSEL-ISPOLZOVANIYA ENDPOINTS START **/
async function getTseliIspolzovaniya() {
    return await $api.get('/tselIspolzovaniyaAuto/list')
}

async function getTseliIspolzovaniyaWithPagination({ page, size }) {
    return await $api.get('/tselIspolzovaniyaAuto/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getTselIspolzovaniyaById({ id }) {
    return await $api.get('/tselIspolzovaniyaAuto/getById/' + id)
}

async function createTselIspolzovaniya({ code, nameRu, nameKg }) {
    return await $api.post('/tselIspolzovaniyaAuto/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editTselIspolzovaniya({ id, code, nameRu, nameKg }) {
    return await $api.put('/tselIspolzovaniyaAuto/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteTselIspolzovaniya({ id }) {
    return await $api.delete('/tselIspolzovaniyaAuto/delete', {
        params: {
            id,
        }
    })
}

/** TSEL-ISPOLZOVANIYA ENDPOINTS END **/


/** TYPE-ORGANISATION ENDPOINTS START **/
async function getOrganisationTypes() {
    return await $api.get('/typeOrganisation/list')
}

async function getOrganisationTypesWithPagination({ page, size }) {
    return await $api.get('/typeOrganisation/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getOrganisationTypeById({ id }) {
    return await $api.get('/typeOrganisation/getById/' + id)
}

async function createOrganisationType({ code, nameRu, nameKg }) {
    return await $api.post('/typeOrganisation/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editOrganisationType({ id, code, nameRu, nameKg }) {
    return await $api.put('/typeOrganisation/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteOrganisationType({ id }) {
    return await $api.delete('/typeOrganisation/delete', {
        params: {
            id,
        }
    })
}

/** TYPE-ORGANISATION ENDPOINTS END **/

/** TYPE-FILIAL ENDPOINTS START **/
async function getFilialTypes() {
    return await $api.get('/typeFilial/list')
}

async function getFilialTypesWithPagination({ page, size }) {
    return await $api.get('/typeFilial/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getFilialTypeById({ id }) {
    return await $api.get('/typeFilial/getById/' + id)
}

async function createFilialType({ code, nameRu, nameKg }) {
    return await $api.post('/typeFilial/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editFilialType({ id, code, nameRu, nameKg }) {
    return await $api.put('/typeFilial/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteFilialType({ id }) {
    return await $api.delete('/typeFilial/delete', {
        params: {
            id,
        }
    })
}

/** TYPE-FILIAL ENDPOINTS END **/

/** BLANK-STATUS ENDPOINTS START **/
async function getBlankStatuses() {
    return await $api.get('/statusBlank/list')
}

async function getBlankStatusesWithPagination({ page, size }) {
    return await $api.get('/statusBlank/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getBlankStatusById({ id }) {
    return await $api.get('/statusBlank/getById/' + id)
}

async function createBlankStatus({ code, nameRu, nameKg }) {
    return await $api.post('/statusBlank/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editBlankStatus({ id, code, nameRu, nameKg }) {
    return await $api.put('/statusBlank/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteBlankStatus({ id }) {
    return await $api.delete('/statusBlank/delete', {
        params: {
            id,
        }
    })
}
/** BLANK-STATUS ENDPOINTS END **/

/** METOD-OPLATY ENDPOINTS START **/
async function getMetodOplaty() {
    return await $api.get('/metodOplaty/list')
}

async function getMetodOplatyWithPagination({ page, size }) {
    return await $api.get('/metodOplaty/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getMetodOplatyId({ id }) {
    return await $api.get('/metodOplaty/getById' + id)
}

async function createMetodOplaty({ code, nameRu, nameKg }) {
    return await $api.post('/metodOplaty/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editMetodOplaty({ id, code, nameRu, nameKg }) {
    return await $api.put('/metodOplaty/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteMetodOplaty({ id }) {
    return await $api.delete('/metodOplaty/delete', {
        params: {
            id,
        }
    })
}
/** BLANK-STATUS ENDPOINTS END **/

/** STATUS-OPLATY ENDPOINTS START **/
async function getStatusOplaty() {
    return await $api.get('/statusOplaty/list')
}

async function getStatusOplatyWithPagination({ page, size }) {
    return await $api.get('/statusOplaty/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getStatusOplatyId({ id }) {
    return await $api.get('/statusOplaty/getById' + id)
}

async function createStatusOplaty({ code, nameRu, nameKg }) {
    return await $api.post('/statusOplaty/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editStatusOplaty({ id, code, nameRu, nameKg }) {
    return await $api.put('/statusOplaty/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteStatusOplaty({ id }) {
    return await $api.delete('/statusOplaty/delete', {
        params: {
            id,
        }
    })
}
/** STATUS-OPLATY ENDPOINTS END **/

/** STATUS-STRAH-SLUCHAI ENDPOINTS START **/
async function getStatusStrahSluchay() {
    return await $api.get('/statusStrahSluchay/list')
}

async function getStatusStrahSluchayWithPagination({ page, size }) {
    return await $api.get('/statusStrahSluchay/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getStatusStrahSluchayId({ id }) {
    return await $api.get('/statusStrahSluchay/getById' + id)
}

async function createStatusStrahSluchay({ code, nameRu, nameKg }) {
    return await $api.post('/statusStrahSluchay/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editStatusStrahSluchay({ id, code, nameRu, nameKg }) {
    return await $api.put('/statusStrahSluchay/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteStatusStrahSluchay({ id }) {
    return await $api.delete('/statusStrahSluchay/delete', {
        params: {
            id,
        }
    })
}
/** STATUS-STRAH-SLUCHAI ENDPOINTS END **/

/** STATUS-NEREG-PRETENZIYA ENDPOINTS START **/
async function getStatusNeregPretenziya() {
    return await $api.get('/statusNeregPretenziya/list')
}

async function getStatusNeregPretenziyaWithPagination({ page, size }) {
    return await $api.get('/statusNeregPretenziya/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getStatusNeregPretenziyaId({ id }) {
    return await $api.get('/statusNeregPretenziya/getById' + id)
}

async function createStatusNeregPretenziya({ code, nameRu, nameKg }) {
    return await $api.post('/statusNeregPretenziya/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editStatusNeregPretenziya({ id, code, nameRu, nameKg }) {
    return await $api.put('/statusNeregPretenziya/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteStatusNeregPretenziya({ id }) {
    return await $api.delete('/statusNeregPretenziya/delete', {
        params: {
            id,
        }
    })
}
/** STATUS-NEREG-PRETENZIYA ENDPOINTS END **/

/** CALCULATE ENDPOINTS START **/
async function getStatusCalculate() {
    return await $api.get('/calculateDictionary/list')
}

async function getStatusCalculateWithPagination({ page, size }) {
    return await $api.get('/calculateDictionary/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getCalculateId({ id }) {
    return await $api.get('/calculateDictionary/getById' + id)
}

async function createCalculate(params) {
    return await $api.post('/calculateDictionary/create', {
        autoInKRDa: params.autoInKRDa,
        autoInKRNet: params.autoInKRNet,
        diagKartaDa: params.diagKartaDa,
        diagKartaNet: params.diagKartaNet,
        neogLicDa: params.neogLicDa,
        neogLicNet: params.neogLicNet,
        stavka: params.stavka
    })
}

async function editStatusCalculate(params) {
    return await $api.put('/calculateDictionary/update', {
        autoInKRDa: params.autoInKRDa,
        autoInKRNet: params.autoInKRNet,
        diagKartaDa: params.diagKartaDa,
        diagKartaNet: params.diagKartaNet,
        neogLicDa: params.neogLicDa,
        neogLicNet: params.neogLicNet,
        stavka: params.stavka,
        id: params.id
    })
}

async function deleteCalculate({ id }) {
    return await $api.delete('/calculateDictionary/delete', {
        params: {
            id,
        }
    })
}
/** CALCULATE ENDPOINTS END **/

/** DAY ENDPOINTS START **/
async function getDay() {
    return await $api.get('/dayDictionary/list')
}

async function getDayWithPagination({ page, size }) {
    return await $api.get('/dayDictionary/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getDayId({ id }) {
    return await $api.get('/dayDictionary/getById' + id)
}

async function createDay(params) {
    return await $api.post('/dayDictionary/create', {
        dayPo: params.dayPo,
        dayS: params.dayS,
        name: params.name,
        stavka: params.stavka
    })
}

async function editDay(params) {
    return await $api.put('/dayDictionary/update', {
        dayPo: params.dayPo,
        dayS: params.dayS,
        name: params.name,
        stavka: params.stavka,
        id: params.id
    })
}

async function deleteDay({ id }) {
    return await $api.delete('/dayDictionary/delete', {
        params: {
            id,
        }
    })
}
/** DAY ENDPOINTS END **/

/** STAJ ENDPOINTS START **/
async function getStaj() {
    return await $api.get('/stajDictionary/list')
}

async function getStajWithPagination({ page, size }) {
    return await $api.get('/stajDictionary/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getStajId({ id }) {
    return await $api.get('/stajDictionary/getById' + id)
}

async function createStaj(params) {
    return await $api.post('/stajDictionary/create', {
        name: params.name,
        stajPo: params.stajPo,
        stajS: params.stajS,
        stavka: params.stavka,
        vozrastS: params.vozrastS,
        vozrastPo: params.vozrastPo
    })
}

async function editStaj(params) {
    return await $api.put('/stajDictionary/update', {
        name: params.name,
        stajPo: params.stajPo,
        stajS: params.stajS,
        stavka: params.stavka,
        id: params.id,
        vozrastS: params.vozrastS,
        vozrastPo: params.vozrastPo
    })
}

async function deleteStaj({ id }) {
    return await $api.delete('/stajDictionary/delete', {
        params: {
            id,
        }
    })
}
/** STAJ ENDPOINTS END **/

/** STAJ ENDPOINTS START **/
async function getBonus() {
    return await $api.get('/bonusMalusDictionary/list')
}

async function getBonusWithPagination({ page, size }) {
    return await $api.get('/bonusMalusDictionary/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getBonusId({ id }) {
    return await $api.get('/bonusMalusDictionary/getById' + id)
}

async function createBonus(params) {
    return await $api.post('/bonusMalusDictionary/create', {
        bolletri: params.bolletri,
        dva: params.dva,
        name: params.name,
        nol: params.nol,
        odin: params.odin,
        stavka: params.stavka,
        tri: params.tri
    })
}

async function editBonus(params) {
    return await $api.put('/bonusMalusDictionary/update', {
        bolletri: params.bolletri,
        dva: params.dva,
        name: params.name,
        nol: params.nol,
        odin: params.odin,
        stavka: params.stavka,
        tri: params.tri,
        id: params.id
    })
}

async function deleteBonus({ id }) {
    return await $api.delete('/bonusMalusDictionary/delete', {
        params: {
            id,
        }
    })
}
/** STAJ ENDPOINTS END **/


/** PARTNER ENDPOINTS START **/
async function getPartner() {
    return await $api.get('/partner/list')
}

async function getPartnerWithPagination({ page, size }) {
    return await $api.get('/partner/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getPartnerId({ id }) {
    return await $api.get('/partner/getById' + id)
}

async function createPartner(params) {
    return await $api.post('/partner/create', {
        name: params.name,
        filials: params.filials
    })
}

async function editPartner(params) {
    return await $api.put('/partner/update', {
        name: params.name,
        filials: params.filials,
        id: params.id
    })
}

async function deletePartner({ id }) {
    return await $api.delete('/partner/delete', {
        params: {
            id,
        }
    })
}
/** PARTNER ENDPOINTS END **/

/** PARTNER-PUBLIC ENDPOINTS START **/
async function getPartnerPublic() {
    return await $api.get('/partnerPublic/list')
}

async function getPartnerPublicWithPagination({ page, size }) {
    return await $api.get('/partnerPublic/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getPartnerPublicAll() {
    return await $api.get('/partnerPublic/listPublic')
}

async function getPartnerPublicId({ id }) {
    return await $api.get('/partnerPublic/getById' + id)
}

async function createPartnerPublic(formData) {
    return await $api.post('/partnerPublic/create', formData)
}

async function editPartnerPublic(formData) {
    return await $api.put('/partnerPublic/update', formData)
}

async function deletePartnerPublic({ id }) {
    return await $api.delete('/partnerPublic/delete', {
        params: {
            id,
        }
    })
}
/** PARTNER-PUBLIC ENDPOINTS END **/

/** TYPE INVALID ENDPOINTS START **/
async function getTypeInvalids() {
    return await $api.get('/typeInvalid/list')
}

async function getTypeInvalidsWithPagination({ page, size }) {
    return await $api.get('/typeInvalid/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getTypeInvalidId({ id }) {
    return await $api.get('/typeInvalid/getById', {
        params: {
            id
        }
    })
}

async function createTypeInvalid({ code, nameRu, nameKg }) {
    return await $api.post('/typeInvalid/create', {
        code,
        nameRu,
        nameKg,
    })
}

async function editTypeInvalid({ id, code, nameRu, nameKg }) {
    return await $api.put('/typeInvalid/update', {
        id,
        code,
        nameRu,
        nameKg,
    })
}

async function deleteTypeInvalid({ id }) {
    return await $api.delete('/typeInvalid/delete', {
        params: {
            id,
        }
    })
}
/** TYPE INVALID ENDPOINTS END **/

export default {
    getCountries,
    getCountriesWithPagination,
    getCountryById,
    createCountry,
    editCountry,
    deleteCountry,
    getOblasts,
    getOblastsWithPagination,
    getOblastById,
    createOblast,
    editOblast,
    deleteOblast,
    getRayons,
    getRayonsWithPagination,
    getRayonById,
    createRayon,
    editRayon,
    deleteRayon,
    getRayonByOblastId,
    getTransportTypes,
    getTransportTypesWithPagination,
    getTransportTypeById,
    createTransportType,
    editTransportType,
    deleteTransportType,
    getDocumentTypes,
    getDocumentTypesWithPagination,
    getDocumentTypeById,
    createDocumentType,
    editDocumentType,
    deleteDocumentType,
    getTseliIspolzovaniya,
    getTseliIspolzovaniyaWithPagination,
    getTselIspolzovaniyaById,
    createTselIspolzovaniya,
    editTselIspolzovaniya,
    deleteTselIspolzovaniya,
    getOrganisationTypes,
    getOrganisationTypesWithPagination,
    getOrganisationTypeById,
    createOrganisationType,
    editOrganisationType,
    deleteOrganisationType,
    getFilialTypes,
    getFilialTypesWithPagination,
    getFilialTypeById,
    createFilialType,
    editFilialType,
    deleteFilialType,
    getBlankStatuses,
    getBlankStatusesWithPagination,
    getBlankStatusById,
    createBlankStatus,
    editBlankStatus,
    deleteBlankStatus,

    getMetodOplaty,
    getMetodOplatyWithPagination,
    getMetodOplatyId,
    createMetodOplaty,
    editMetodOplaty,
    deleteMetodOplaty,

    getStatusOplaty,
    getStatusOplatyWithPagination,
    getStatusOplatyId,
    createStatusOplaty,
    editStatusOplaty,
    deleteStatusOplaty,

    getStatusStrahSluchay,
    getStatusStrahSluchayWithPagination,
    getStatusStrahSluchayId,
    createStatusStrahSluchay,
    editStatusStrahSluchay,
    deleteStatusStrahSluchay,

    getStatusNeregPretenziya,
    getStatusNeregPretenziyaWithPagination,
    getStatusNeregPretenziyaId,
    createStatusNeregPretenziya,
    editStatusNeregPretenziya,
    deleteStatusNeregPretenziya,

    getStatusCalculate,
    getStatusCalculateWithPagination,
    getCalculateId,
    createCalculate,
    editStatusCalculate,
    deleteCalculate,

    getDay,
    getDayWithPagination,
    getDayId,
    createDay,
    editDay,
    deleteDay,

    getStaj,
    getStajWithPagination,
    getStajId,
    createStaj,
    editStaj,
    deleteStaj,

    getBonus,
    getBonusWithPagination,
    getBonusId,
    createBonus,
    editBonus,
    deleteBonus,

    getPartner,
    getPartnerWithPagination,
    getPartnerId,
    createPartner,
    editPartner,
    deletePartner,

    getPartnerPublic,
    getPartnerPublicWithPagination,
    getPartnerPublicAll,
    getPartnerPublicId,
    createPartnerPublic,
    editPartnerPublic,
    deletePartnerPublic,

    getTypeInvalids,
    getTypeInvalidsWithPagination,
    getTypeInvalidId,
    createTypeInvalid,
    editTypeInvalid,
    deleteTypeInvalid,
}
