import $api from '@/http'

/** ORGANISATIONS ENDPOINTS START **/
async function getOrganisations() {
    return await $api.get('/organisation/list')
}

async function getOrganisationsWithPagination({ page, size }) {
    return await $api.get('/organisation/list', {
        params: {
            page: page,
            size: size,
        }
    })
}

async function getOrganisationById({ id }) {
    return await $api.get('/organisation/getById', {
        params: {
            id,
        }
    })
}

async function createOrganisation(formData) {
    return await $api.post('/organisation/create', formData)
}

async function editOrganisation(formData) {
    return await $api.put('/organisation/update', formData)
}

async function deleteOrganisation({ id }) {
    return await $api.delete('/organisation/delete', {
        params: {
            id,
        }
    })
}

async function blockOrganisation({ id }) {
    return await $api.post('/organisation/blockOrganisation', {}, {
        params: {
            id
        }
    })
}

async function unblockOrganisation({ id }) {
    return await $api.post('/organisation/unblockOrganisation', {},{
        params: {
            id
        }
    })
}

/** ORGANISATIONS ENDPOINTS END **/


export default {
    getOrganisations,
    getOrganisationsWithPagination,
    getOrganisationById,
    createOrganisation,
    editOrganisation,
    deleteOrganisation,
    blockOrganisation,
    unblockOrganisation,
}