import $api from '@/http'

/** FILIALS ENDPOINTS START **/
async function getFilials() {
    return await $api.get('/filial/list')
}

async function getFilialsWithPagination({ page, size }) {
    return await $api.get('/filial/list', {
        params: {
            pageNo: page,
            pageSize: size,
        }
    })
}

async function getFilialById({ id }) {
    return await $api.get('/filial/getById', {
        params: {
            id
        }
    })
}

async function getFilialByOrgId({ organisationId }) {
    return await $api.get('/filial/listByOrganisationId', {
        params: {
            organisationId,
            pageNo: 1,
            pageSize: 10000,
        }
    })
}

async function getFilialByOrgIdWithPagination({ organisationId, page, size }) {
    return await $api.get('/filial/listByOrganisationId', {
        params: {
            organisationId,
            pageNo: page,
            pageSize: size,
        }
    })
}

async function createFilial(formData) {
    return await $api.post('/filial/create', formData)
}

async function editFilial(formData) {
    return await $api.put('/filial/update', formData)
}

async function deleteFilial({ id }) {
    return await $api.delete('/filial/delete', {
        params: {
            id,
        }
    })
}

/** FILIALS ENDPOINTS END **/


export default {
    getFilials,
    getFilialsWithPagination,
    getFilialById,
    getFilialByOrgId,
    getFilialByOrgIdWithPagination,
    createFilial,
    editFilial,
    deleteFilial,
}