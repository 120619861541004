import $api from '@/http'

async function singIn(payload) {
    try {
        const response = await $api.post('/authenticate', payload)
        return {user: response.data, status: response.status}
    } catch (e) {
        return {error: e.response.data, status: e.response.status}
    }
}

async function emailAuth(flag, id) {
    return await $api.put('/emailAuthenticator', {}, {
        params: {
            flag,
            id,
        }
    })
}

async function googleAuth(flag, id) {
    return await $api.put('/googleAuthenticator', {}, {
        params: {
            flag,
            id,
        }
    })
}

async function getQRCode(username) {
    return await $api.get('/qrcode', {
        params: {
            username,
        }
    })
}

async function verify(payload) {
    return await $api.post('/verify', {}, {
        params: payload
    })
}

export default {
    singIn,
    emailAuth,
    googleAuth,
    getQRCode,
    verify,
}
