import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/layouts/top-menu/Main'),
    children: [
      {
        path: '/',
        name: 'Main',
        permission: [''],
        component: () => import('@/views/public/Main')
      },
    ]
  },
  {
    path: '/workspace',
    name: 'Workspace',
    component: () => import('@/layouts/side-menu/Main'),
    children: [
      {
        path: 'access-control',
        name: 'AccessControl',
        permission: ['SUPER_ADMIN'],
        component: () => import('@/views/users/access-control/Main')
      },
      {
        path: 'user-role',
        name: 'UserRole',
        permission: ['SUPER_ADMIN'],
        component: () => import('@/views/users/user-role/Main')
      },
      {
        path: 'profile',
        name: 'Profile',
        permission: ['DEFAULT'],
        component: () => import('@/views/profile/Main')
      },
      {
        path: 'countries',
        name: 'Countries',
        permission: ['DICT_READ'],
        component: () => import('@/views/dictionaries/countries/Main.vue')
      },
      {
        path: 'type-filial',
        name: 'TypeFilial',
        permission: ['DICT_READ'],
        component: () => import('@/views/dictionaries/type-filial/Main.vue')
      },
      {
        path: 'oblasts',
        name: 'Oblasts',
        permission: ['DICT_READ'],
        component: () => import('@/views/dictionaries/oblasts/Main.vue')
      },
      {
        path: 'rayons',
        name: 'Rayons',
        permission: ['DICT_READ'],
        component: () => import('@/views/dictionaries/rayons/Main.vue')
      },
      {
        path: 'organisation-list',
        name: 'OrganisationList',
        permission: ['ORG_READ'],
        component: () => import('@/views/organisations/Main.vue')
      },
      {
        path: 'organisation-add',
        name: 'OrganisationAdd',
        permission: ['ORG_ADD'],
        component: () => import('@/views/organisations/MainAdd.vue')
      },
      {
        path: 'organisation-edit',
        name: 'OrganisationEdit',
        permission: ['ORG_EDIT'],
        component: () => import('@/views/organisations/MainEdit.vue')
      },
      {
        path: 'organisation-filials',
        name: 'OrganisationFilials',
        permission: ['FILIAL_READ'],
        component: () => import('@/views/organisations/MainFilials.vue')
      },
      {
        path: 'filials',
        name: 'Filials',
        permission: ['FILIAL_READ'],
        component: () => import('@/views/organisations/MainFilialsForSideMenu.vue')
      },
      {
        path: 'users',
        name: 'Users',
        permission: ['SUPER_ADMIN'],
        component: () => import('@/views/users/users/Main')
      },
      {
        path: 'agents',
        name: 'Agents',
        permission: ['AGENT_READ'],
        component: () => import('@/views/agents/register/Main')
      },
      {
        path: 'add-application',
        name: 'AddApplication',
        permission: ['SUPER_ADMIN'],
        component: () => import('@/views/imushestvo/ApplicationCreate')
      },
      {
        path: 'application-list',
        name: 'ApplicationList',
        permission: ['SUPER_ADMIN'],
        component: () => import('@/views/imushestvo/ApplicationList')
      },

      {
        path: 'usluga',
        name: 'Usluga',
        permission: ['DICT_READ'],
        component: () => import('@/views/dictionaries/usluga/Main.vue')
      },
    ]
  },

  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('@/views/auth/SignIn')
  },
  {
    path: '/sign-out',
    name: 'SignOut',
    component: {
      beforeRouteEnter(to, from, next) {
        const destination = {
          path: '/',
          query: from.query,
          params: from.params
        }
        store.dispatch('auth/signOut')
        next(destination)
      }
    }
  },
  {
    path: '/404',
    name: 'ErrorPage',
    component: () => import('@/views/error-page/Main')
  },
  {
    path: '/403',
    name: 'ErrorPage403',
    component: () => import('@/views/error-page/Main403')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error-page/Main')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: -400,
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/workspace', '/sign-in', '/404', '/sign-out', '/403']

  const path = to.path
  let pathNew = to.path
  const authRequired = !publicPages.includes(path)
  const loggedIn = store.state.auth.user
  pathNew = pathNew.substr(1)
  let perm = null

  let tmp = pathNew.split('/')
  tmp.pop()
  let ed = ''
  tmp.forEach(item => {
    ed += item + '/';
  })
  ed += ':id'

  if (ed === 'chek-police/:id') {
    next()
  }

  routes[1].children.forEach(item => {
    if ("workspace/" + item.path === pathNew) {
      perm = item.permission
    } else {
      let tempPath = pathNew.split('/')
      tempPath.pop()
      let edited = ''
      tempPath.forEach(item => {
        edited += item + '/';
      })
      edited += ':id'

      if ("workspace/" + item.path === edited) {
        perm = item.permission
      }
    }
  })
  if (authRequired && !loggedIn) {
    next('/sign-in')
  } else if (publicPages.includes(path)) {
    if (path === '/workspace' && !loggedIn) {
      next('/sign-in')
    } else {
      next()
    }
  } else {
    if (loggedIn && store.state.auth.user.permissions != null) {
      if (path === '/') {
        next('/workspace')
      }
      const userPermissions = JSON.parse(
        JSON.stringify(store.state.auth.user.permissions)
      )
      if (checkArray(userPermissions, perm)) {
        next()
      } else {
        next({
          name: 'ErrorPage403'
        })
      }
    } else {
      next({
        name: 'ErrorPage403'
      })
    }
  }
})
const checkArray = function (userPermissions, permission) {
  let isAccess = false
  if (permission != null || permission != undefined) {
    userPermissions.forEach(item => {
      permission.forEach(item2 => {
        if (item.name === item2 || item.name === 'SUPER_ADMIN' || item2 === 'DEFAULT') {
          isAccess = true
        }
      })
    })
  }

  return isAccess
}


export default router
