import $api from '@/http'

/** APPLICATION ENDPOINTS START **/

async function getApplicationsWithPagination({ page, size }) {
    return await $api.get('/zayavka/list', {
        params: {
            pageNo: page,
            pageSize: size,
        }
    })
}

async function getReport1() {
    return await $api.get('/pdf/downloadPdf1', {
        responseType: 'blob',
    })
}
async function getReport2() {
    return await $api.get('/pdf/downloadPdf2', {
        responseType: 'blob',
    })
}
async function getReport3() {
    return await $api.get('/pdf/downloadPdf3', {
        responseType: 'blob',
    })
}
async function getReport4() {
    return await $api.get('/pdf/downloadPdf4', {
        responseType: 'blob',
    })
}

/** APPLICATION ENDPOINTS END **/


export default {
    getApplicationsWithPagination,
    getReport1,
    getReport2,
    getReport3,
    getReport4
}